import React from "react";
import { Link } from "react-router-dom";

export default function CtaOne() {
  return (
    <section className="nft3-call-to-action position-relative overflow-hidden">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="cta-img-wrap d-none d-lg-block">
              <img
                src="assets/img/cta-img-with-bg.png"
                alt="cta "
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cta-content ptb-50">
              <h2>Create, Sell & Collect Your Very Fast NFTs From Neftron.</h2>
              <p>
                The purpose of lorem ipsum is to create a natural looking block
                of text (sentence, paragraph, page, etc.) that doesn't distract
                from the layout. A practice not without controversy, laying out
                pages with meaningless
              </p>
              <div className="primary-btn cta-action-btn">
                <Link to="/wallet">Connect Wallet</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/cta-shape-right.png"
        alt="cta shape"
        className="cta-shape-right position-absolute"
      />
    </section>
  );
}
