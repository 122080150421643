import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Countdown from "react-countdown";
import "swiper/css/navigation";
import { Link } from "react-router-dom";

export default function HotDropsSlider() {
  const swiperOptions = {
    slidesPerView: 1,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    navigation: {
      nextEl: ".swiper-nav-control .swiper-button-next",
      prevEl: ".swiper-nav-control .swiper-button-prev",
    },
  };
  return (
    <>
      <Swiper
        className="nft2-hc-slider"
        modules={[Navigation]}
        {...swiperOptions}
      >
        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="assets/img/home2/hc-art.jpg"
                className="img-fluid rounded-custom w-100"
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">3d aesthetics with shapes</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/creator-profile-1.jpg"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Creators</span>
                        <h6>Leslie Alexander</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/creator-profile-2.jpg"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Collection</span>
                        <h6>Trading Cards</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>current bids</span>
                      <h4>1.24 ETH</h4>
                      <span className="currency">($1,456.123)</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="auction-bid bid-amount">
                      <span>Aucton ending inc</span>
                      <Countdown
                        date={Date.now() + 5000000000}
                        renderer={counter}
                        autoStart={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="primary-btn">
                    <Link to="/product-details">Place a Bid</Link>
                  </div>
                  <div className="secondary-btn ms-3">
                    <Link to="/product-details">View Items</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="assets/img/home2/hc-art-2.jpg"
                className="img-fluid rounded-custom w-100"
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">3d aesthetics with shapes</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/creator-profile-1.jpg"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Creators</span>
                        <h6>Leslie Alexander</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/creator-profile-2.jpg"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Collection</span>
                        <h6>Trading Cards</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>current bids</span>
                      <h4>1.24 ETH</h4>
                      <span className="currency">($1,456.123)</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="auction-bid bid-amount">
                      <span>Aucton ending inc</span>
                      <Countdown
                        date={Date.now() + 5000000000}
                        renderer={counter}
                        autoStart={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="primary-btn">
                    <Link to="/product-details">Place a Bid</Link>
                  </div>
                  <div className="secondary-btn ms-3">
                    <Link to="/product-details">View Items</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-nav-control custom-control">
        <span className="swiper-button-next">
          <i className="fa-solid fa-arrow-left"></i>
        </span>
        <span className="swiper-button-prev">
          <i className="fa-solid fa-arrow-right"></i>
        </span>
      </div>
    </>
  );
}

const counter = ({ days, hours, minutes, seconds }) => {
  return (
    <ul className="downcount-timer">
      <li>
        <h4 className="days">{days}</h4>
        <span>Days</span>
      </li>
      <li>
        <h4 className="hours">{hours}</h4>
        <span>Hour</span>
      </li>
      <li>
        <h4 className="minutes">{minutes}</h4>
        <span>Min</span>
      </li>
      <li>
        <h4 className="seconds">{seconds}</h4>
        <span>Sec</span>
      </li>
    </ul>
  );
};
