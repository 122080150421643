import { allNFts, digitalArt, musics } from "../../utils/data";
import React from "react";
import { Link } from "react-router-dom";

export default function PopularNfts() {
  return (
    <section className="nft3-popular-filter position-relative ptb-120">
      <div className="container">
        <div className="nft3-filter-top">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="filter-title">
                <h2 className="mb-0">Most popular NFT</h2>
              </div>
            </div>
            <div className="col-lg-8">
              <ul
                className="nav nft3-filter-btns justify-content-center justify-content-lg-end"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-all-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-all"
                    type="button"
                    role="tab"
                    aria-controls="pills-all"
                    aria-selected="true"
                  >
                    All NFTs
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-digitalart-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-digitalart"
                    type="button"
                    role="tab"
                    aria-controls="pills-digitalart"
                    aria-selected="false"
                  >
                    Digital art
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-music-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-music"
                    type="button"
                    role="tab"
                    aria-controls="pills-music"
                    aria-selected="false"
                  >
                    Music
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-photography-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-photography"
                    type="button"
                    role="tab"
                    aria-controls="pills-photography"
                    aria-selected="false"
                  >
                    Photography
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-sports-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-sports"
                    type="button"
                    role="tab"
                    aria-controls="pills-sports"
                    aria-selected="false"
                  >
                    Sports
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row g-3 grid-wrapper">
          <div className="col-12">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-all"
                role="tabpanel"
                aria-labelledby="pills-all-tab"
              >
                <div className="row">
                  {allNFts.map((nft, i) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={i + 1}>
                      <div
                        className={`nft3-filter-item-wrapper mb-4 ${nft.classOption}`}
                      >
                        <div className="thumb-wrapper">
                          <Link to={nft.nftDetails}>
                            <img
                              src={nft.nftImage}
                              alt="nft item"
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="item-title">
                          <Link to="/product-details">
                            <h2 className="h6">{nft.nftName}</h2>
                          </Link>
                        </div>
                        <div className="item-meta d-flex align-items-center justify-content-between">
                          <span className="amount">
                            <i className="fa-brands fa-ethereum"></i>{" "}
                            {nft.nftPrice}
                          </span>
                          <span className="like-count">
                            <i className="fa-solid fa-heart"></i> {nft.nftLikes}
                          </span>
                        </div>
                        <div className="item-btns d-flex align-items-center">
                          <Link to={nft.nftDetails} className="btn item-btn-1">
                            view history
                          </Link>
                          <Link to={nft.nftDetails} className="btn item-btn-2">
                            Place bid
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-digitalart"
                role="tabpanel"
                aria-labelledby="pills-digitalart-tab"
              >
                <div className="row">
                  {digitalArt.map((dArt, i) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={i + 1}>
                      <div
                        className={`nft3-filter-item-wrapper mb-4 ${dArt.classOption}`}
                      >
                        <div className="thumb-wrapper">
                          <Link to={dArt.nftDetails}>
                            <img
                              src={dArt.nftImage}
                              alt="nft item"
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="item-title">
                          <Link to="/product-details">
                            <h2 className="h6">{dArt.nftName}</h2>
                          </Link>
                        </div>
                        <div className="item-meta d-flex align-items-center justify-content-between">
                          <span className="amount">
                            <i className="fa-brands fa-ethereum"></i>{" "}
                            {dArt.nftPrice}
                          </span>
                          <span className="like-count">
                            <i className="fa-solid fa-heart"></i>{" "}
                            {dArt.nftLikes}
                          </span>
                        </div>
                        <div className="item-btns d-flex align-items-center">
                          <Link to={dArt.nftDetails} className="btn item-btn-1">
                            view history
                          </Link>
                          <Link to={dArt.nftDetails} className="btn item-btn-2">
                            Place bid
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-music"
                role="tabpanel"
                aria-labelledby="pills-music-tab"
              >
                <div className="row">
                  {musics.map((mus, i) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={i + 1}>
                      <div
                        className={`nft3-filter-item-wrapper mb-4 ${mus.classOption}`}
                      >
                        <div className="thumb-wrapper">
                          <Link to={mus.nftDetails}>
                            <img
                              src={mus.nftImage}
                              alt="nft item"
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="item-title">
                          <Link to="/product-details">
                            <h2 className="h6">{mus.nftName}</h2>
                          </Link>
                        </div>
                        <div className="item-meta d-flex align-items-center justify-content-between">
                          <span className="amount">
                            <i className="fa-brands fa-ethereum"></i>
                            {mus.nftPrice}
                          </span>
                          <span className="like-count">
                            <i className="fa-solid fa-heart"></i> {mus.nftLikes}
                          </span>
                        </div>
                        <div className="item-btns d-flex align-items-center">
                          <Link to={mus.nftDetails} className="btn item-btn-1">
                            view history
                          </Link>
                          <Link to={mus.nftDetails} className="btn item-btn-2">
                            Place bid
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-photography"
                role="tabpanel"
                aria-labelledby="pills-photography-tab"
              >
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="nft3-filter-item-wrapper mb-4">
                      <div className="thumb-wrapper">
                        <Link to="/product-details">
                          <img
                            src="assets/img/home3/nft-art-3.jpg"
                            alt="nft item"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="item-title">
                        <Link to="/product-details">
                          <h2 className="h6">Cool Cops Club</h2>
                        </Link>
                      </div>
                      <div className="item-meta d-flex align-items-center justify-content-between">
                        <span className="amount">
                          <i className="fa-brands fa-ethereum"></i> 0.95 ETH
                        </span>
                        <span className="like-count">
                          <i className="fa-solid fa-heart"></i> 1.5k like
                        </span>
                      </div>
                      <div className="item-btns d-flex align-items-center">
                        <Link to="/product-details" className="btn item-btn-1">
                          view history
                        </Link>
                        <Link to="/product-details" className="btn item-btn-2">
                          Place bid
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="nft3-filter-item-wrapper mb-4">
                      <div className="thumb-wrapper">
                        <Link to="/product-details">
                          <img
                            src="assets/img/home3/nft-art-7.jpg"
                            alt="nft item"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="item-title">
                        <Link to="/product-details">
                          <h2 className="h6">Hair Color Gost Eye</h2>
                        </Link>
                      </div>
                      <div className="item-meta d-flex align-items-center justify-content-between">
                        <span className="amount">
                          <i className="fa-brands fa-ethereum"></i> 0.564 ETH
                        </span>
                        <span className="like-count">
                          <i className="fa-solid fa-heart"></i> 4.5k like
                        </span>
                      </div>
                      <div className="item-btns d-flex align-items-center">
                        <Link to="/product-details" className="btn item-btn-1">
                          view history
                        </Link>
                        <Link to="/product-details" className="btn item-btn-2">
                          Place bid
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-sports"
                role="tabpanel"
                aria-labelledby="pills-sports-tab"
              >
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="nft3-filter-item-wrapper mb-4">
                      <div className="thumb-wrapper">
                        <Link to="/product-details">
                          <img
                            src="assets/img/home3/nft-art-4.jpg"
                            alt="nft item"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="item-title">
                        <Link to="/product-details">
                          <h2 className="h6">Nice Hair Style</h2>
                        </Link>
                      </div>
                      <div className="item-meta d-flex align-items-center justify-content-between">
                        <span className="amount">
                          <i className="fa-brands fa-ethereum"></i> 0.225 ETH
                        </span>
                        <span className="like-count">
                          <i className="fa-solid fa-heart"></i> 1.5k like
                        </span>
                      </div>
                      <div className="item-btns d-flex align-items-center">
                        <Link to="/product-details" className="btn item-btn-1">
                          view history
                        </Link>
                        <Link to="/product-details" className="btn item-btn-2">
                          Place bid
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="nft3-filter-item-wrapper mb-4">
                      <div className="thumb-wrapper">
                        <Link to="/product-details">
                          <img
                            src="assets/img/home3/nft-art-8.jpg"
                            alt="nft item"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="item-title">
                        <Link to="/product-details">
                          <h2 className="h6">Robotic Ape Social</h2>
                        </Link>
                      </div>
                      <div className="item-meta d-flex align-items-center justify-content-between">
                        <span className="amount">
                          <i className="fa-brands fa-ethereum"></i> 3.58 ETH
                        </span>
                        <span className="like-count">
                          <i className="fa-solid fa-heart"></i> 1.5k like
                        </span>
                      </div>
                      <div className="item-btns d-flex align-items-center">
                        <Link to="/product-details" className="btn item-btn-1">
                          view history
                        </Link>
                        <Link to="/product-details" className="btn item-btn-2">
                          Place bid
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/popular-filter-shape-bottom.png"
        alt="shape"
        className="shape-right position-absolute"
      />
    </section>
  );
}
