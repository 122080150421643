import React from "react";
import Pagination from "./common/Pagination";

const ActivityTable = () => {
  return (
    <section className="nft-activity-area pt-120 pb-120 position-relative overflow-hidden">
      <img
        src="assets/img/shapes/hero-top-left.svg"
        alt="shape"
        className="shape-top-left position-absolute"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="activity-filter">
              <div className="type-select">
                <h6>Event type</h6>
                <div className="type-select-box mt-3">
                  <select>
                    <option>Listing items</option>
                    <option>sales item</option>
                    <option>transfers</option>
                    <option>bids</option>
                  </select>
                </div>
              </div>

              <div className="type-select">
                <h6>Collections</h6>
                <div className="type-select-box mt-3">
                  <select>
                    <option>Oasis Doodles</option>
                    <option>Azuki</option>
                    <option>Beanz Official</option>
                    <option>Shinsei Galverse</option>
                    <option>Arcade Land</option>
                    <option>Crypto Punks</option>
                  </select>
                </div>
              </div>

              <div className="type-select">
                <h6>Chains</h6>
                <div className="type-select-box mt-3">
                  <select>
                    <option>Ethereum</option>
                    <option>Polygon</option>
                    <option>Klaytn</option>
                    <option>Solana</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="activity-list">
              <div className="table-responsive">
                <table className="table table-striped table-bordered align-middle">
                  <thead>
                    <tr>
                      <th>Item name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/mask.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Otherdeed for otherside</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-2.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Lee Complete #110</span>
                          <h6>Otherdeed for otherside</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-3.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Otherdeed for otherside</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-4.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Lee Complete #110</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-5.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Otherdeed for otherside</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-4.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Lee Complete #110</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-6.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Otherdeed for otherside</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-7.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Lee Complete #110</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-8.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Otherdeed for otherside</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="item-info d-flex align-items-center">
                        <div className="thumb-wrapper">
                          <img
                            src="assets/img/ac-6.png"
                            className="img-fluid rounded-circle"
                            alt="mask"
                          />
                        </div>
                        <div className="item-content ms-3">
                          <span>Genesis Aliens</span>
                          <h6>Lee Complete #110</h6>
                        </div>
                      </td>
                      <td>
                        <div className="price-amount text-center">
                          <span>
                            <i className="fa-brands fa-ethereum"></i>0.0005
                          </span>
                          <span className="d-block ms-4">$5.48</span>
                        </div>
                      </td>
                      <td>
                        <div className="quantity text-center">
                          <span>01</span>
                        </div>
                      </td>
                      <td>
                        <div className="sellar text-center">
                          <span>Suncity</span>
                        </div>
                      </td>
                      <td>
                        <div className="buyer text-center">
                          <span>Hongjun</span>
                        </div>
                      </td>
                      <td>
                        <div className="time text-center">
                          <span>20 Minutes ago</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination />
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/hero-top-right.svg"
        alt="shape"
        className="shape-right-top position-absolute"
      />
      <img
        src="assets/img/shapes/hero-top-right.svg"
        alt="shape"
        className="right-0 shape-bottom position-absolute"
      />
    </section>
  );
};

export default ActivityTable;
