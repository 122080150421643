import React from "react";
import { Link } from "react-router-dom";
import HotDropsSlider from "./HotDropsSlider";

const HotDrops = () => {
  return (
    <section className="hot-collection overflow-hidden ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center">
              <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
                <h2 className="mb-0">NFT Recent hot drops</h2>
                <span className="title-shape ms-3">
                  <img
                    src="assets/img/home2/title-fire-shape.png"
                    alt="fire-shape"
                    width="45"
                  />
                </span>
              </div>
              <p>
                Progressively architect magnetic e-services whereas
                client-focused expertise. Enthusiastically technically sound
                results via cross-platform.
              </p>
            </div>
          </div>
        </div>
        <div className="hot-collection-content">
          <div className="row">
            <div className="col-lg-6">
              <HotDropsSlider />
            </div>
            <div className="col-lg-6">
              <div className="row g-4 justify-content-center">
                <div className="col-sm-6">
                  <div className="nft-hc-item">
                    <div className="thumb-wrapper">
                      <Link to="/product-details">
                        <img
                          src="assets/img/home2/thumb-1.jpg"
                          className="img-fluid"
                          alt="item-thumb"
                        />
                      </Link>
                    </div>
                    <div className="save-wish">
                      <button>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                    <div className="hc-item-title">
                      <h6>Lee complete #102</h6>
                    </div>
                    <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                      <div>
                        <span>current bid</span>
                        <h6>2.41 ETH</h6>
                      </div>
                      <span className="like-count">
                        <i className="fa-solid fa-heart"></i> 1.4k like
                      </span>
                    </div>
                    <div className="spacer"></div>
                    <div className="hc-item-author-info d-flex align-items-center">
                      <img
                        src="assets/img/home2/author-1.jpg"
                        className="img-fluid rounded-circle"
                        alt="author"
                      />
                      <div className="author-title">
                        <h6>Jane cooper</h6>
                        <span>@janecooper</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="nft-hc-item">
                    <div className="thumb-wrapper">
                      <Link to="/product-details">
                        <img
                          src="assets/img/home2/thumb-2.jpg"
                          className="img-fluid"
                          alt="item-thumb"
                        />
                      </Link>
                    </div>
                    <div className="save-wish">
                      <button>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                    <div className="hc-item-title">
                      <h6>PharaGods-NFT</h6>
                    </div>
                    <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                      <div>
                        <span>current bid</span>
                        <h6>2.21 ETH</h6>
                      </div>
                      <span className="like-count">
                        <i className="fa-solid fa-heart"></i> 1.2k like
                      </span>
                    </div>
                    <div className="spacer"></div>
                    <div className="hc-item-author-info d-flex align-items-center">
                      <img
                        src="assets/img/home2/author-2.jpg"
                        className="img-fluid rounded-circle"
                        alt="author"
                      />
                      <div className="author-title">
                        <h6>Alexander</h6>
                        <span>@lesliealexander</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="nft-hc-item">
                    <div className="thumb-wrapper">
                      <Link to="/product-details">
                        <img
                          src="assets/img/home2/thumb-3.jpg"
                          className="img-fluid"
                          alt="item-thumb"
                        />
                      </Link>
                    </div>
                    <div className="save-wish">
                      <button>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                    <div className="hc-item-title">
                      <h6>Project Arowana Prime</h6>
                    </div>
                    <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                      alt="author"/
                      <div>
                        <span>current bid</span>
                        <h6>2.11 ETH</h6>
                      </div>
                      <span className="like-count">
                        <i className="fa-solid fa-heart"></i> 1.25k like
                      </span>
                    </div>
                    <div className="spacer"></div>
                    <div className="hc-item-author-info d-flex align-items-center">
                      <img
                        src="assets/img/home2/author-3.jpg"
                        className="img-fluid rounded-circle"
                        alt="author"
                      />
                      <div className="author-title">
                        <h6>Williamson</h6>
                        <span>@octavelevei</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="nft-hc-item">
                    <div className="thumb-wrapper">
                      <Link to="/product-details">
                        <img
                          src="assets/img/home2/thumb-4.jpg"
                          className="img-fluid"
                          alt="item-thumb"
                        />
                      </Link>
                    </div>
                    <div className="save-wish">
                      <button>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                    <div className="hc-item-title">
                      <h6>Otherdeed for otherside</h6>
                    </div>
                    <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                      <div>
                        <span>current bid</span>
                        <h6>2.23 ETH</h6>
                      </div>
                      <span className="like-count">
                        <i className="fa-solid fa-heart"></i> 1.6k like
                      </span>
                    </div>
                    <div className="spacer"></div>
                    <div className="hc-item-author-info d-flex align-items-center">
                      <img
                        src="assets/img/home2/author-4.jpg"
                        className="img-fluid rounded-circle"
                        alt="author"
                      />
                      <div className="author-title">
                        <h6>Guy Hawkins</h6>
                        <span>@octavelevei</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotDrops;
