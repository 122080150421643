// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";

export default function FeedBack() {
  const swiperOptions = {
    slidesPerView: 3,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 2,
    loop: true,
    navigation: {
      nextEl: ".swiper-nav-control .swiper-button-next",
      prevEl: ".swiper-nav-control .swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };
  return (
    <section className="nft3-feedback-area position-relative overflow-hidden ptb-120">
      <img
        src="assets/img/shapes/dark-mode-gradient.svg"
        alt="shape"
        className="position-absolute center-shape"
      />
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6">
            <div className="nft3-feedback-title text-center">
              <h2>Our customers feedback</h2>
              <p>
                Non-fungible tokens or NFTs are bits of digital files that live
                on the internet. The underlying technology of blockchain
                possible.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Swiper
              {...swiperOptions}
              className="nft3-feedback-slider margin-top-30 position-relative"
              modules={[Navigation]}
            >
              <SwiperSlide>
                <div className="nft3-feedback-single">
                  <div className="feedback-top">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/home3/fc-1.jpg"
                        alt="author"
                        className="img-fluid"
                      />
                    </div>
                    <div className="title-n-rating">
                      <h6>Arturo A. Miller</h6>
                      <ul className="rating-star list-inline">
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="feedback-comment">
                    <p>
                      “Non-fungible tokens or NFTs are bits of digital files
                      that live on the internet. Non-fungible tokens or NFTs are
                      bits of digital files that live on the internet live on
                      the internet”
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="nft3-feedback-single">
                  <div className="feedback-top">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/home3/fc-2.jpg"
                        alt="author"
                        className="img-fluid"
                      />
                    </div>
                    <div className="title-n-rating">
                      <h6>Christopher P. Pearce</h6>
                      <ul className="rating-star list-inline">
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="feedback-comment">
                    <p>
                      “Non-fungible tokens or NFTs are bits of digital files
                      that live on the internet. Non-fungible tokens or NFTs are
                      bits of digital files that live on the internet live on
                      the internet”
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="nft3-feedback-single">
                  <div className="feedback-top">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/home3/fc-3.jpg"
                        alt="author"
                        className="img-fluid"
                      />
                    </div>
                    <div className="title-n-rating">
                      <h6>Arturo A. Miller</h6>
                      <ul className="rating-star list-inline">
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="feedback-comment">
                    <p>
                      “Non-fungible tokens or NFTs are bits of digital files
                      that live on the internet. Non-fungible tokens or NFTs are
                      bits of digital files that live on the internet live on
                      the internet”
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="nft3-feedback-single">
                  <div className="feedback-top">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/home3/fc-1.jpg"
                        alt="author"
                        className="img-fluid"
                      />
                    </div>
                    <div className="title-n-rating">
                      <h6>Albert R. Williams</h6>
                      <ul className="rating-star list-inline">
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="fas fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="feedback-comment">
                    <p>
                      “Non-fungible tokens or NFTs are bits of digital files
                      that live on the internet. Non-fungible tokens or NFTs are
                      bits of digital files that live on the internet live on
                      the internet”
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="swiper-nav-control">
              <span className="swiper-button-next">
                <i className="fa-solid fa-arrow-left"></i>
              </span>
              <span className="swiper-button-prev">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
