import React from "react";

export default function PartnersBrand() {
  return (
    <div className="nft1-partner-brands pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="hwit-title text-center">
              <h2>Our trusted partner</h2>
              <p>
                Non-fungible tokens or NFTs are on their own to become the base
                layer of the Metaverse. The benefits of blockchain technology
                are obvious.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="nft1-brands-list">
          <div className="row g-4">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="assets/img/home2/brand-1.png"
                  alt="brand"
                  width="130"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="assets/img/home2/brand-2.png"
                  alt="brand"
                  width="130"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="assets/img/home2/brand-3.png"
                  alt="brand"
                  width="130"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="brand-wrapper">
                <img
                  src="assets/img/home2/brand-4.png"
                  alt="brand"
                  width="130"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
