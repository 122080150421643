import React from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";
import WalletList from "../components/WalletList";

const Wallet = () => {
  return (
    <Layout title="Wallet">
      <BreadCrumb
        pageTitle="Wallet"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Wallet"
      />
      <WalletList />
    </Layout>
  );
};

export default Wallet;
