import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { navDemo, navPagesData } from "../../../utils/data";
import OffCanvas from "./OffCanvas";
import Countdown from "react-countdown";

export default function Navbar() {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <>
      <header className="main-header position-absolute w-100">
        <nav
          className={`navbar navbar-expand-xl navbar-dark sticky-header ${
            scroll > headerTop ? "affix" : ""
          }`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img src="assets/img/logo.png" alt="logo" className="img-fluid" />
            </Link>

            <a
              className="navbar-toggler position-absolute right-0 border-0"
              href="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="fas fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </a>
            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Home
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0">
                    <div className="dropdown-grid rounded-custom width-half">
                      <div className="dropdown-grid-item">
                        {navDemo.map((data, i) => (
                          <Link
                            to={data.path}
                            className="dropdown-link demo-link"
                            key={i + 1}
                          >
                            <span className="demo-list secondary-bg rounded-circle text-white">
                              {i + 1}
                            </span>
                            <div className="dropdown-info">
                              <div className="drop-title">{data.menuTitle}</div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/activity" className="nav-link">
                    Activity
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Collectors
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0">
                    <div className="dropdown-grid rounded-custom width-full-3">
                      <div className="dropdown-grid-item first-item">
                        <Link
                          to="/explore-collections"
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/mega-thumb-1.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Trading Card</h6>
                            <span>140 items</span>
                          </div>
                        </Link>
                        <Link
                          to="/explore-collections"
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/mega-thumb-2.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Domain names</h6>
                            <span>125 items</span>
                          </div>
                        </Link>
                        <Link
                          to="/explore-collections"
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/mega-thumb-3.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Photography</h6>
                            <span>135 items</span>
                          </div>
                        </Link>
                        <Link
                          to="/explore-collections"
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/mega-thumb-5.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Collectibles</h6>
                            <span>155 items</span>
                          </div>
                        </Link>
                      </div>
                      <div className="dropdown-grid-item second-item">
                        <Link
                          to="/explore-collections"
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/mega-thumb-4.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>virtual Worlds</h6>
                            <span>140 items</span>
                          </div>
                        </Link>
                        <Link
                          to="/explore-collections"
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/mega-thumb-5.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Collections</h6>
                            <span>146 items</span>
                          </div>
                        </Link>
                        <Link
                          to="/explore-collections"
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/mega-thumb-6.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Trading Card</h6>
                            <span>170 items</span>
                          </div>
                        </Link>
                        <Link
                          to="/explore-collections"
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/mega-thumb-2.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Solana NFTs</h6>
                            <span>160 items</span>
                          </div>
                        </Link>
                      </div>
                      <div className="dropdown-grid-item last-item radius-right-side">
                        <div className="nft3-filter-item-wrapper">
                          <ul
                            className="downcount-timer"
                            data-date="05/24/2022 23:59:59"
                          >
                            <li>
                              <span>
                                <i className="fa-solid fa-clock"></i>
                              </span>
                            </li>
                            <Countdown
                              date={Date.now() + 1000000000}
                              className="fw-bold text-white"
                            ></Countdown>
                          </ul>
                          <div className="thumb-wrapper">
                            <Link to="/product-auction-details">
                              <img
                                src="assets/img/home3/nft-art-2.jpg"
                                alt="nft item"
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                          <div className="item-title">
                            <Link to="/product-auction-details">
                              <h2 className="h6">The Creative Dragon</h2>
                            </Link>
                          </div>
                          <div className="item-meta d-flex align-items-center justify-content-between">
                            <span className="amount">
                              <i className="fa-brands fa-ethereum"></i> 0.45 ETH
                            </span>
                            <span className="like-count">
                              <i className="fa-solid fa-heart"></i> 5k like
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Blog
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-dark">
                    <div className="dropdown-grid rounded-custom width-half">
                      <div className="dropdown-grid-item">
                        <Link to="/blog-grid" className="dropdown-link px-0">
                          <span className="drop-title">Blog Grid</span>
                        </Link>
                        <Link to="/blog-list" className="dropdown-link px-0">
                          <span className="drop-title">Blog List</span>
                        </Link>
                        <Link to="/blog-details" className="dropdown-link px-0">
                          <span className="drop-title">Blog Details</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Pages
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0">
                    <div className="dropdown-grid rounded-custom width-half">
                      <div className="dropdown-grid-item">
                        {navPagesData.map((data, i) => (
                          <Link
                            key={i + 1}
                            to={data.path}
                            className="dropdown-link px-0"
                          >
                            <span className="drop-title">{data.title}</span>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/contact-us" className="nav-link">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <div className="action-btns d-flex align-items-center text-end me-5 me-xl-0 d-none d-sm-block d-md-flex d-lg-flex">
              <form className="header-search-form" action="#">
                <input type="text" placeholder="Search items" />
                <button type="submit">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>
              </form>
              <div className="primary-btn ms-3">
                <Link to="/wallet" className="primary-btn d-none d-md-block">
                  Connect Wallet
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <OffCanvas />
    </>
  );
}
