import React from "react";

const WalletList = () => {
  return (
    <section
      className="nft-wallet-list pt-120 pb-90"
      style={{ backgroundImage: 'url("assets/img/wallet-bg.jpg")' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="wallet-lists">
              <a href="https://metamask.io" target="_blank" rel="noreferrer">
                <div className="single-wallet">
                  <div className="wallet-left">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/meta-mask.png"
                        alt="wallet"
                        className="img-fluid"
                      />
                    </div>
                    <div className="wallet-title">
                      <h5>Meta mask</h5>
                    </div>
                  </div>
                  <div className="wallet-label">
                    <span>popular</span>
                  </div>
                </div>
              </a>
              <a href="https://authereum.com" target="_blank" rel="noreferrer">
                <div className="single-wallet">
                  <div className="wallet-left">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/authereum.png"
                        alt="wallet"
                        className="img-fluid"
                      />
                    </div>
                    <div className="wallet-title">
                      <h5>Authereum</h5>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="https://www.coinbase.com"
                target="_blank"
                rel="noreferrer"
              >
                <div className="single-wallet">
                  <div className="wallet-left">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/coinbase.png"
                        alt="wallet"
                        className="img-fluid"
                      />
                    </div>
                    <div className="wallet-title">
                      <h5>Coinbase</h5>
                    </div>
                  </div>
                </div>
              </a>
              <a href="https://www.bitski.com" target="_blank" rel="noreferrer">
                <div className="single-wallet">
                  <div className="wallet-left">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/bitski.png"
                        alt="wallet"
                        className="img-fluid"
                      />
                    </div>
                    <div className="wallet-title">
                      <h5>Bitski</h5>
                    </div>
                  </div>
                </div>
              </a>
              <a href="https://fortmatic.com" target="_blank" rel="noreferrer">
                <div className="single-wallet">
                  <div className="wallet-left">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/formatic.png"
                        alt="wallet"
                        className="img-fluid"
                      />
                    </div>
                    <div className="wallet-title">
                      <h5>Formatic</h5>
                    </div>
                  </div>
                </div>
              </a>
              <a href="https://www.venly.io" target="_blank" rel="noreferrer">
                <div className="single-wallet">
                  <div className="wallet-left">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/arkane.png"
                        alt="wallet"
                        className="img-fluid"
                      />
                    </div>
                    <div className="wallet-title">
                      <h5>Arkane</h5>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WalletList;
