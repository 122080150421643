import React from "react";
import { Link } from "react-router-dom";
import { topCollectionData } from "../../utils/data";

const TopCollections = () => {
  return (
    <section className="nft3-top-collection position-relative pb-120">
      <div className="container">
        <div className="row justify-content-lg-between">
          <div className="col-sm-6">
            <div className="title-headline text-center text-lg-start text-md-start text-sm-center">
              <h2 className="mb-0">Top collections.</h2>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="explore-btn text-center text-lg-end text-md-end text-sm-center mt-3 mt-sm-0">
              <Link to="/collections">
                View all
                <span>
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-4 g-3">
          {topCollectionData.map((item, i) => (
            <div className="col-lg-3 col-md-4 col-sm-6" key={i + 1}>
              <Link
                to="/explore-collections"
                className="nft3-collection-item d-flex align-items-center"
              >
                <img
                  src={item.collectionImage}
                  alt="collection"
                  className="img-fluid rounded-circle"
                />
                <div className="content-wrapper">
                  <h6>{item.collectionName}</h6>
                  <span>{item.itemCount}</span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <img
        src="assets/img/shapes/shape-right.png"
        alt="shape"
        className="shape-right position-absolute"
      />
    </section>
  );
};

export default TopCollections;
