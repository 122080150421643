import React from "react";
import SimilarCollection from "../components/collections/SimilarCollection";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";
import NftDetails from "../components/NftDetails";

const ProductDetails = () => {
  return (
    <Layout title="Product Details">
      <BreadCrumb
        pageTitle="Product Details"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Product Details"
      />
      <NftDetails />
      <SimilarCollection />
    </Layout>
  );
};

export default ProductDetails;
