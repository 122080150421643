import React from "react";
import { Link } from "react-router-dom";
import { Scrollbar } from "smooth-scrollbar-react";

const HeroRight = () => {
  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: "bounce",
        },
      }}
    >
      <div className="hero-item-slider">
        <div className="hrs-item">
          <div className="thumb-wrapper">
            <Link to="/product-details">
              <img
                src="assets/img/home3/hero-slide-1.jpg"
                alt="iteme"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="hrs-item-content">
            <div className="author">
              <span>By barry tone</span>
            </div>
            <div className="item-title">
              <Link to="/product-details">
                <h6>3d aesthetics with shapes</h6>
              </Link>
            </div>
            <div className="item-meta">
              <span>
                <i className="fa-brands fa-ethereum"></i>0.0-74 ETH
              </span>
            </div>
          </div>
        </div>
        <div className="hrs-item">
          <div className="thumb-wrapper">
            <Link to="/product-details">
              <img
                src="assets/img/home3/hero-slide-2.jpg"
                alt="iteme"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="hrs-item-content">
            <div className="author">
              <span>By barry tone</span>
            </div>
            <div className="item-title">
              <Link to="/product-details">
                <h6>Anonymous Genesis</h6>
              </Link>
            </div>
            <div className="item-meta">
              <span>
                <i className="fa-brands fa-ethereum"></i>0.0-74 ETH
              </span>
            </div>
          </div>
        </div>
        <div className="hrs-item">
          <div className="thumb-wrapper">
            <Link to="/product-details">
              <img
                src="assets/img/home3/hero-slide-3.jpg"
                alt="iteme"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="hrs-item-content">
            <div className="author">
              <span>By barry tone</span>
            </div>
            <div className="item-title">
              <Link to="/product-details">
                <h6>GoldSilver Pirates</h6>
              </Link>
            </div>
            <div className="item-meta">
              <span>
                <i className="fa-brands fa-ethereum"></i>0.0-74 ETH
              </span>
            </div>
          </div>
        </div>
        <div className="hrs-item">
          <div className="thumb-wrapper">
            <Link to="/product-details">
              <img
                src="assets/img/home3/hero-slide-4.jpg"
                alt="iteme"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="hrs-item-content">
            <div className="author">
              <span>By barry tone</span>
            </div>
            <div className="item-title">
              <Link to="/product-details">
                <h6>Shadowy Super Coder</h6>
              </Link>
            </div>
            <div className="item-meta">
              <span>
                <i className="fa-brands fa-ethereum"></i>0.0-74 ETH
              </span>
            </div>
          </div>
        </div>
        <div className="hrs-item">
          <div className="thumb-wrapper">
            <Link to="/product-details">
              <img
                src="assets/img/home3/hero-slide-1.jpg"
                alt="iteme"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="hrs-item-content">
            <div className="author">
              <span>By barry tone</span>
            </div>
            <div className="item-title">
              <Link to="/product-details">
                <h6>Championship Series NFTs</h6>
              </Link>
            </div>
            <div className="item-meta">
              <span>
                <i className="fa-brands fa-ethereum"></i>0.0-74 ETH
              </span>
            </div>
          </div>
        </div>
        <div className="hrs-item">
          <div className="thumb-wrapper">
            <Link to="/product-details">
              <img
                src="assets/img/home3/hero-slide-2.jpg"
                alt="iteme"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="hrs-item-content">
            <div className="author">
              <span>By barry tone</span>
            </div>
            <div className="item-title">
              <Link to="/product-details">
                <h6>GRONK Championship LI</h6>
              </Link>
            </div>
            <div className="item-meta">
              <span>
                <i className="fa-brands fa-ethereum"></i>0.0-74 ETH
              </span>
            </div>
          </div>
        </div>
      </div>
    </Scrollbar>
  );
};

export default HeroRight;
