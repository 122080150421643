import React from "react";
import PartnersBrand from "../components/brands/PartnersBrand";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";
import CreatorsList from "../components/tabs/CreatorsList";

const Creators = () => {
  return (
    <Layout>
      <BreadCrumb
        pageTitle="Creators"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Creators"
      />
      <CreatorsList />
      <PartnersBrand />
    </Layout>
  );
};

export default Creators;
