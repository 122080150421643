import React from "react";
import { Link } from "react-router-dom";

export const LatestNftsArticle = () => {
  return (
    <section className="nft3-blog-area position-relative ptb-120">
      <span className=""></span>
      <img
        src="assets/img/home3/blog-shape-1.png"
        alt="shape"
        className="position-absolute shape-top-left"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="nft3-blog-title text-center">
              <h2>Latest NFTs Articles</h2>
              <p>
                Lowest Fees & Unlimited Rewards. We Have it all to provide the
                best experience As a pleasant surprise for crypto fans, a
                unique.
              </p>
            </div>
          </div>
        </div>
        <div className="blog-content">
          <div className="row">
            <div className="col-lg-6">
              <div className="nft3-article">
                <article>
                  <div className="thumb-wrapper">
                    <Link to="/blog-details">
                      <img
                        src="assets/img/home3/article-1.jpg"
                        alt="blog"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="news-wrapper">
                    <div className="news-top">
                      <div className="token-btn">
                        <Link to="#">Token</Link>
                      </div>
                      <div className="comment-btn">
                        <Link to="#">
                          <i className="fa-solid fa-comment"></i>39 Comment
                        </Link>
                      </div>
                    </div>
                    <div className="news-title">
                      <Link to="/blog-details">
                        <h2 className="h4 fw-bold">
                          Enhance your NFT Marketplace Appearance by Adding a
                          Background Image
                        </h2>
                      </Link>
                    </div>
                    <div className="author-details">
                      <div className="thumb-wrapper">
                        <img
                          src="assets/img/home3/author-1.jpg"
                          alt="blog"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="author-info">
                        <h6>Leslie alexander</h6>
                        <span>April 31, 2022</span>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="nft3-news-right">
                <div className="nft3-news-column">
                  <article>
                    <div className="thumb-wrapper">
                      <Link to="/blog-details">
                        <img
                          src="assets/img/home3/article-2.jpg"
                          alt="blog"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="news-wrapper">
                      <div className="news-top">
                        <div className="token-btn">
                          <Link to="#">Token</Link>
                        </div>
                        <div className="comment-btn">
                          <Link to="#">
                            <i className="fa-solid fa-comment"></i>39 Comment
                          </Link>
                        </div>
                      </div>
                      <div className="news-title">
                        <Link to="/blog-details">
                          <h2 className="h5 fw-bold">
                            How to Invest in Bitcoin 2021: Best Ways to Get
                            Started
                          </h2>
                        </Link>
                      </div>
                      <div className="author-details">
                        <div className="author-thumb">
                          <img
                            src="assets/img/home3/author-2.jpg"
                            alt="author"
                            className="img-fluid rounded-circle"
                          />
                        </div>
                        <div className="author-info">
                          <h6>K. Slavin</h6>
                          <span>April 31, 2022</span>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="nft3-news-column">
                  <article>
                    <div className="thumb-wrapper">
                      <Link to="/blog-details">
                        <img
                          src="assets/img/home3/article-3.jpg"
                          alt="article"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="news-wrapper">
                      <div className="news-top">
                        <div className="token-btn">
                          <Link to="#">Token</Link>
                        </div>
                        <div className="comment-btn">
                          <Link to="#">
                            <i className="fa-solid fa-comment"></i>39 Comment
                          </Link>
                        </div>
                      </div>
                      <div className="news-title">
                        <Link to="/blog-details">
                          <h2 className="h5 fw-bold">
                            Making Sense of Bitcoin Cryptocurrency and
                            Blockchain.
                          </h2>
                        </Link>
                      </div>
                      <div className="author-details">
                        <div className="author-thumb">
                          <img
                            src="assets/img/home3/author-1.jpg"
                            alt="author"
                            className="img-fluid rounded-circle"
                          />
                        </div>
                        <div className="author-info">
                          <h6>Leslie alexander</h6>
                          <span>April 31, 2022</span>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="primary-btn nft3-news-explore-btn">
                  <span className="arrow-shape">
                    <img src="assets/img/arrow-shape.png" alt="arrow" />
                  </span>
                  <Link to="/blog-grid">More blogs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
