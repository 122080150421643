import React from "react";
import { Link } from "react-router-dom";
import {
  footerMenuOne,
  footerMenuThree,
  footerMenuTwo,
} from "../../../utils/data";

const Footer = () => {
  return (
    <footer className="footer-style-3">
      <div className="footer-top position-relative overflow-hidden pt-100 pb-0 pb-sm-5">
        <img
          src="assets/img/shapes/footer-top-right.png"
          alt="shape"
          className="footer-right-shape position-absolute"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="nft3-footer-widget">
                <div className="footer-title">
                  <h4 className="h5">Marketplace</h4>
                </div>
                <div className="footer-nav">
                  <ul>
                    {footerMenuOne.map((data, i) => (
                      <li key={i + 1}>
                        <Link to={data.menuPath}>{data.menuTitle}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="nft3-footer-widget">
                <div className="footer-title">
                  <h4 className="h5">My Accounts</h4>
                </div>
                <div className="footer-nav">
                  <ul>
                    {footerMenuTwo.map((data, i) => (
                      <li key={i + 1}>
                        <Link to={data.menuPath}>{data.menuTitle}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="nft3-footer-widget sb-widget">
                <div className="footer-title">
                  <h4 className="h5">Resources</h4>
                </div>
                <div className="footer-nav">
                  <ul>
                    {footerMenuThree.map((data, i) => (
                      <li key={i + 1}>
                        <Link to={data.menuPath}>{data.menuTitle}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="nft3-footer-widget">
                <div className="footer-title">
                  <h4 className="h5">Subscrive Newsletter</h4>
                </div>
                <div className="footer-desc">
                  <p>
                    If you're an NFT enthusiast, or are looking to downloading
                    our NFT App.
                  </p>
                </div>
                <div className="footer-sb-form">
                  <form className="newsletter-form mx-auto">
                    <input type="email" placeholder="Type your email" />
                    <button type="submit">Submit now</button>
                  </form>
                </div>
                <div className="footer-social">
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-behance"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-dribbble"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/shapes/footer-bottom-left.png"
          alt="shape"
          className="footer-left-shape position-absolute"
        />
      </div>
      <div className="footer-bottom footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="copyright-left">
                <p>Copyright by themestags. All Rights Reserved.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="copyright-right">
                <p>Elite Author on ThemeForest.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
