import React from "react";
import { LatestNftsArticle } from "../../components/blogs/LatestNftsArticle";
import TopCollections from "../../components/collections/TopCollections";
import CtaOne from "../../components/cta/CtaOne";
import FeedBack from "../../components/feedback/FeedBack";
import Layout from "../../components/layout/Layout";
import PromoOne from "../../components/promo/PromoOne";
import StepOne from "../../components/steps/StepOne";
import PopularNfts from "../../components/tabs/PopularNfts";
import TopSeller from "../../components/top-seller/TopSeller";
import HeroOne from "./HeroOne";

export default function HomeOne() {
  return (
    <Layout title="Neftron">
      <HeroOne />
      <PromoOne />
      <StepOne />
      <TopSeller />
      <TopCollections />
      <CtaOne />
      <PopularNfts />
      <FeedBack />
      <LatestNftsArticle />
    </Layout>
  );
}
