import React from "react";
import { topCreator, bestSeller, recentAdded } from "../../utils/data";
import { Link } from "react-router-dom";

export default function CreatorsList() {
  return (
    <section className="nft1-creator position-relative overflow-hidden ptb-120">
      <img
        src="assets/img/shapes/hero-top-left.svg"
        alt="shape"
        className="shape-top-left position-absolute"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="nft1-tab-top text-center">
              <h2>Our best creators</h2>
              <p>
                Competently impact market-driven web-readiness with e-business
                catalysts for change. Globally empower client-centered
                deliverables whereas real-time.
              </p>
              <div className="tab-btn mt-4">
                <ul className="nav nav-tabs justify-content-center">
                  <li>
                    <button
                      className="active"
                      data-bs-toggle="tab"
                      data-bs-target="#top-rated"
                    >
                      Top Rated
                    </button>
                  </li>
                  <li>
                    <button data-bs-toggle="tab" data-bs-target="#best-seller">
                      Best Seller
                    </button>
                  </li>
                  <li>
                    <button
                      data-bs-toggle="tab"
                      data-bs-target="#recently-added"
                    >
                      Recent added
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade show active" id="top-rated">
            <div className="row g-3 justify-content-center">
              {topCreator.map((item, i) => (
                <div
                  className="col-xxl-3 col-xl-4 col-md-6 col-sm-11"
                  key={i + 1}
                >
                  <div className="nft1-ct-wrapper">
                    <div className="nft1-creator-column">
                      <div className="thumb-wrapper">
                        <img src={item.creatorImage} alt="Top Rated" />
                      </div>
                      <div className="creator-content">
                        <div className="title-headline">
                          <Link to="/creator-profile">
                            <h6>{item.creatorName}</h6>
                          </Link>
                          <span>{item.userName}</span>
                        </div>
                        <div className="creator-meta">
                          <span>
                            <i className="fa-solid fa-list-ul"></i>
                            {item.nftItem}
                          </span>
                          <span>
                            <i className="fa-solid fa-eye"></i>
                            {item.itemView}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tab-pane fade" id="best-seller">
            <div className="row g-3 justify-content-center">
              {bestSeller.map((item, i) => (
                <div
                  className="col-xxl-3 col-xl-4 col-md-6 col-sm-11"
                  key={i + 1}
                >
                  <div className="nft1-ct-wrapper">
                    <div className="nft1-creator-column">
                      <div className="thumb-wrapper">
                        <img src={item.creatorImage} alt={item.creatorName} />
                      </div>
                      <div className="creator-content">
                        <div className="title-headline">
                          <Link to="/creator-profile">
                            <h6>{item.creatorName}</h6>
                          </Link>
                          <span>{item.userName}</span>
                        </div>
                        <div className="creator-meta">
                          <span>
                            <i className="fa-solid fa-list-ul"></i>
                            {item.nftItem}
                          </span>
                          <span>
                            <i className="fa-solid fa-eye"></i>
                            {item.itemView}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tab-pane fade" id="recently-added">
            <div className="row g-3 justify-content-center">
              {recentAdded.map((item, i) => (
                <div
                  className="col-xxl-3 col-xl-4 col-md-6 col-sm-11"
                  key={i + 1}
                >
                  <div className="nft1-ct-wrapper">
                    <div className="nft1-creator-column">
                      <div className="thumb-wrapper">
                        <img src={item.creatorImage} alt={item.creatorName} />
                      </div>
                      <div className="creator-content">
                        <div className="title-headline">
                          <Link to="/creator-profile">
                            <h6>{item.creatorName}</h6>
                          </Link>
                          <span>{item.userName}</span>
                        </div>
                        <div className="creator-meta">
                          <span>
                            <i className="fa-solid fa-list-ul"></i>
                            {item.nftItem}
                          </span>
                          <span>
                            <i className="fa-solid fa-eye"></i>
                            {item.itemView}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/hero-top-right.svg"
        alt="shape"
        className="shape-right-top position-absolute"
      />
      <img
        src="assets/img/shapes/hero-top-right.svg"
        alt="shape"
        className="right-0 shape-bottom position-absolute"
      />
    </section>
  );
}
