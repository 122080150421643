import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";

export default function TopSeller() {
  const swiperOptions = {
    slidesPerView: 4,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 2,
    loop: true,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      991: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };
  return (
    <section className="sellers-n-buyers pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="seller-slider">
              <div className="sb-top">
                <div className="title-heading">
                  <h2 className="mb-0">Our top sellers</h2>
                </div>
                <div className="explore-btn">
                  <Link to="/creators">
                    View all
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
              <Swiper
                {...swiperOptions}
                modules={[Pagination]}
                className="seller-slider nft3-user-slider"
              >
                <SwiperSlide>
                  <div className="seller-single">
                    <div className="nft3-creator-column rounded">
                      <div className="cover-img">
                        <img
                          src="assets/img/home3/seller-cover-1.jpg"
                          alt="seller cover"
                          className="img-fluid rounded-top"
                        />
                      </div>
                      <div className="profile-wrapper">
                        <img
                          src="assets/img/home3/sp-1.png"
                          alt="top seller"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="item-bottom">
                        <Link to="/creator-profile">
                          <h6>Esther Howard</h6>
                        </Link>
                        <span>@lesiealexander</span>
                      </div>
                      <div className="follow-btn">
                        <Link to="/creator-profile">Following</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="seller-single">
                    <div className="nft3-creator-column rounded">
                      <div className="cover-img">
                        <img
                          src="assets/img/home3/seller-cover-2.jpg"
                          alt="seller cover"
                          className="img-fluid rounded-top"
                        />
                      </div>
                      <div className="profile-wrapper">
                        <img
                          src="assets/img/home3/sp-2.png"
                          alt="top seller"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="item-bottom">
                        <Link to="/creator-profile">
                          <h6>Rodney T. Hoffman</h6>
                        </Link>
                        <span>@lesiealexander</span>
                      </div>
                      <div className="follow-btn">
                        <Link to="/creator-profile">Following</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="seller-single">
                    <div className="nft3-creator-column rounded">
                      <div className="cover-img">
                        <img
                          src="assets/img/home3/seller-cover-3.jpg"
                          alt="seller cover"
                          className="img-fluid rounded-top"
                        />
                      </div>
                      <div className="profile-wrapper">
                        <img
                          src="assets/img/home3/sp-3.png"
                          alt="top seller"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="item-bottom">
                        <Link to="/creator-profile">
                          <h6>Richard T. Swofford</h6>
                        </Link>
                        <span>@lesiealexander</span>
                      </div>
                      <div className="follow-btn">
                        <Link to="/creator-profile">Following</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="seller-single">
                    <div className="nft3-creator-column rounded">
                      <div className="cover-img">
                        <img
                          src="assets/img/home3/seller-cover-2.jpg"
                          alt="seller cover"
                          className="img-fluid rounded-top"
                        />
                      </div>
                      <div className="profile-wrapper">
                        <img
                          src="assets/img/home3/sp-4.png"
                          alt="top seller"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="item-bottom">
                        <Link to="/creator-profile">
                          <h6>Rebecca M. Green</h6>
                        </Link>
                        <span>@lesiealexander</span>
                      </div>
                      <div className="follow-btn">
                        <Link to="/creator-profile">Following</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="seller-single">
                    <div className="nft3-creator-column rounded">
                      <div className="cover-img">
                        <img
                          src="assets/img/home3/seller-cover-1.jpg"
                          alt="seller cover"
                          className="img-fluid rounded-top"
                        />
                      </div>
                      <div className="profile-wrapper">
                        <img
                          src="assets/img/home3/sp-5.jpg"
                          alt="top seller"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="item-bottom">
                        <Link to="/creator-profile">
                          <h6>Josephine J. Davis</h6>
                        </Link>
                        <span>@lesiealexander</span>
                      </div>
                      <div className="follow-btn">
                        <Link to="/creator-profile">Following</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="seller-single">
                    <div className="nft3-creator-column rounded">
                      <div className="cover-img">
                        <img
                          src="assets/img/home3/seller-cover-2.jpg"
                          alt="seller cover"
                          className="img-fluid rounded-top"
                        />
                      </div>
                      <div className="profile-wrapper">
                        <img
                          src="assets/img/home3/sp-6.jpg"
                          alt="top seller"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="item-bottom">
                        <Link to="/creator-profile">
                          <h6>Rebecca T. Pratt</h6>
                        </Link>
                        <span>@lesiealexander</span>
                      </div>
                      <div className="follow-btn">
                        <Link to="/creator-profile">Following</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
